import { UserIcon, ListBulletIcon, EnvelopeIcon, Cog6ToothIcon } from "@heroicons/react/24/outline"
import Link from "next/link"
import React from "react"
import { LogoutButton } from "./logoutButton"
import { Session } from "next-auth"
import clsx from "clsx"

type Props = {
  session: Session | null
  className?: string
}

export const UserDropdown: React.FC<Props> = ({ session, className }) => {
  const avatarImage = session?.user?.image || session?.user?.picture
  const email = session?.user?.email
  const initials = session?.user?.name ? session.user.name.match(/\b(\w)/g)?.join("") : ""
  return (
    <>
      {/* <LogoutButton /> */}
      <div className="ml-4 dropdown dropdown-end">
        <label tabIndex={0} className={clsx("btn btn-ghost btn-circle avatar", { placeholder: !avatarImage })}>
          <div className="w-10 rounded-full">
            {avatarImage ? (
              <img src={avatarImage} referrerPolicy="no-referrer" />
            ) : (
              <span className="text-3xl">{initials}</span>
            )}
          </div>
        </label>
        <ul
          tabIndex={0}
          className="p-2 mt-3 w-52 shadow menu menu-compact dropdown-content bg-base-100 rounded-box z-10"
        >
          <li className="text-xs pl-4 border-b mb-2 pb-2 text-gray-700 break-all">{email}</li>
          <li>
            <Link href="/app">Home</Link>
          </li>
          <li>
            <Link href="/app/profile">
              <UserIcon className="w-4 text-gray-600" />
              Profile
            </Link>
          </li>
          <li>
            <Link href="/app/list">
              <ListBulletIcon className="w-4 text-gray-600" />
              Data Collections
            </Link>
          </li>
          <li>
            <Link href="/app/settings">
              <Cog6ToothIcon className="w-4 text-gray-600" />
              Settings
            </Link>
          </li>
          <li className="border-t mt-2 pt-2">
            <LogoutButton />
          </li>
        </ul>
      </div>
    </>
  )
}
