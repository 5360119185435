export const navigation = [
  {
    title: "Introduction",
    links: [
      { title: "Welcome", href: "/docs" },
      { title: "About", href: "/docs/about" },
    ],
  },
  {
    title: "Core concepts",
    links: [
      { title: "Data collections", href: "/docs/data" },
      // {
      //   title: "Interfaces",
      //   href: "/docs/interfaces",
      // },
      // {
      //   title: "Website Hosting",
      //   href: "/docs/configure",
      // },
      // { title: "Share", href: "/docs/share" },
      // {
      //   title: "Visualization",
      //   href: "/docs/visualization",
      // },
      // { title: "Your profile", href: "/docs/profile" },
    ],
  },
  // {
  //   title: "Advanced guides",
  //   links: [
  //     { title: "Writing plugins", href: "/docs/writing-plugins" },
  //     { title: "Neuralink integration", href: "/docs/neuralink-integration" },
  //     { title: "Temporal paradoxes", href: "/docs/temporal-paradoxes" },
  //     { title: "Testing", href: "/docs/testing" },
  //     { title: "Compile-time caching", href: "/docs/compile-time-caching" },
  //     {
  //       title: "Predictive data generation",
  //       href: "/docs/predictive-data-generation",
  //     },
  //   ],
  // },
  // {
  //   title: "API reference",
  //   links: [
  //     { title: "CacheAdvance.predict()", href: "/docs/cacheadvance-predict" },
  //     { title: "CacheAdvance.flush()", href: "/docs/cacheadvance-flush" },
  //     { title: "CacheAdvance.revert()", href: "/docs/cacheadvance-revert" },
  //     { title: "CacheAdvance.regret()", href: "/docs/cacheadvance-regret" },
  //   ],
  // },
  // {
  //   title: "Contributing",
  //   links: [
  //     { title: "How to contribute", href: "/docs/how-to-contribute" },
  //     { title: "Architecture guide", href: "/docs/architecture-guide" },
  //     { title: "Design principles", href: "/docs/design-principles" },
  //   ],
  // },
]
