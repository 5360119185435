function LogomarkPaths() {
  return <img className="inline-block w-6 align-middle stroke-current" src="/img/sens_center_gr.svg" alt="" />
}

export function Logomark(props: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div {...props}>
      <LogomarkPaths />
    </div>
  )
}

export function Logo(props: React.ComponentPropsWithoutRef<"div">) {
  return (
    <div className="flex flex-row items-center" {...props}>
      <LogomarkPaths />
      <img className="inline-block pb-1 ml-2 w-32 stroke-current" src="/img/sens_center_black3.svg" alt="" />
    </div>
  )
}
