import(/* webpackMode: "eager" */ "/Users/cyber/dev/sens/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.23.6_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-_7muul4d5clrxd3lwpgrmtm774q/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/Users/cyber/dev/sens/node_modules/.pnpm/next@15.0.0-rc.0_@babel+core@7.23.6_@opentelemetry+api@1.9.0_babel-plugin-macros@3.1.0_react-_7muul4d5clrxd3lwpgrmtm774q/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/cyber/dev/sens/web/src/app/(doc)/doc.css");
;
import(/* webpackMode: "eager", webpackExports: ["AppProviders"] */ "/Users/cyber/dev/sens/web/src/app/appProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DocsLayoutHeader"] */ "/Users/cyber/dev/sens/web/src/component/doc/LayoutHeader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navigation"] */ "/Users/cyber/dev/sens/web/src/component/doc/Navigation.tsx");
;
import(/* webpackMode: "eager" */ "/Users/cyber/dev/sens/web/src/images/blur-cyan.png");
;
import(/* webpackMode: "eager" */ "/Users/cyber/dev/sens/web/src/images/blur-indigo.png");
;
import(/* webpackMode: "eager" */ "/Users/cyber/dev/sens/web/src/styles/globals.css");
